<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}中药</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="中药名称" prop="name">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="药品类别" prop="medicineCategoryId" class="medicineClass clearfix">
				<el-select
					class="float-l"
					v-model="ruleForm.medicineCategoryId"
					placeholder="请选择"
					:disabled="isShowDetail"
					@change="changeSelect($event, 'medicineCategoryId')"
				>
					<el-option v-for="item in medicineTypeList1" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<span class="float-l">—</span>
				<el-select
					class="float-l"
					v-model="ruleForm.medicineCategoryDictionaryItemId"
					placeholder="请选择"
					:disabled="isShowDetail"
					@change="changeSelect($event, 'medicineCategoryDictionaryItemId')"
				>
					<el-option v-for="item in medicineTypeList2" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="药用" prop="medical">
				<el-input v-model.trim="ruleForm.medical" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="药性" prop="drugProperty">
				<el-input v-model.trim="ruleForm.drugProperty" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="功效" prop="attention">
				<el-input v-model.trim="ruleForm.attention" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="应用" prop="indication">
				<el-input v-model.trim="ruleForm.indication" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="用法用量" prop="specification">
				<el-input v-model.trim="ruleForm.specification" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="使用注意" prop="benefits">
				<el-input v-model.trim="ruleForm.benefits" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="上传图片" prop="images">
				<Upload
					ref="uploadImage"
					v-model="ruleForm.images"
					modulePath="MEDICINE"
					@uploadImg="uploadImg"
					operator="zhangl"
					:maxCount="3"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
		ImageAndVideo: () => import('@/components/ImageAndVideo/index.vue'),
	},
	data() {
		return {
			createdVisible: false,
			ruleForm: {
				name: '',
				medicineCategoryId: '',
				medicineCategoryDictionaryItemId: '',
				medical: '', // 药用
				drugProperty: '',
				effect: '',
				indication: '',
				specification: '',
				benefits: '',
				images: [],
			},
			medicineTypeList1: [],
			medicineTypeList2: [],
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				medicineCategoryId: [{ required: true, message: '请选择', trigger: 'blur' }],
				medicineCategoryDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				medical: [{ required: true, message: '请输入', trigger: 'blur' }],
				drugProperty: [{ required: true, message: '请输入', trigger: 'blur' }],
				effect: [{ required: true, message: '请输入', trigger: 'blur' }],
				indication: [{ required: true, message: '请输入', trigger: 'blur' }],
				specification: [{ required: true, message: '请输入', trigger: 'blur' }],
				benefits: [{ required: true, message: '请输入', trigger: 'blur' }],
				images: [{ required: true, message: '请上传', trigger: 'blur' }],
			},
			saveLoading: false,
			link: '',
			attachmentInfo: [],
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.link = data.link;
			this.getMedicineTypeList();
			this.getAttachment();
			if (data.row) {
				this.getDetail(data.link);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取详情
		getDetail(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						this.setRuleForm(res.data.collection[0]);
					}
				})
				.catch((e) => {});
		},
		// 获取附件组
		getAttachment() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'MEDICINE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取药品类型
		getMedicineTypeList() {
			this.$http
				.get(this.api['Category#index'].href, { params: { categorySchemeCode: 'tcm' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.medicineTypeList1 = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		changeSelect(val, type) {
			if (type == 'medicineCategoryId') {
				this.ruleForm.medicineCategoryDictionaryItemId = '';
				this.medicineTypeList2 = [];
				let newArr = JSON.parse(JSON.stringify(this.medicineTypeList1));
				this.medicineTypeList2 = newArr.find((item) => item.id == val).children;
			}
			this.$set(this.ruleForm, type, val);
			this.$forceUpdate();
		},
		// 修改/查看
		setRuleForm(data) {
			this.ruleForm = JSON.parse(JSON.stringify(data));
			console.log(data, 'data');
			this.ruleForm.medicineCategoryId = (data.medicineCategorizing && data.medicineCategorizing[0].id) || null;
			this.medicineTypeList2 = this.medicineTypeList1.find((item) => item.id == this.ruleForm.medicineCategoryId).children;
			this.ruleForm.medicineCategoryDictionaryItemId =
				data.medicineCategorizing.find((v) => v.children && v.children.length > 0).children[0].id || null;
			this.ruleForm.images = data.attachmentMap['PHOTO'].map((item) => {
				return {
					url: item.link,
					uid: item.id,
					title: item.title,
				};
			});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.ruleForm.images = [];
			this.$refs.uploadImage.images = [];
			this.$refs[formName].resetFields();
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						medicineTypeCode: 'C',
						medicineCategory: [this.ruleForm.medicineCategoryDictionaryItemId],
						attachments: this.ruleForm.images.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'PHOTO').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'PHOTO').id,
								mimeType: item.title.split('.')[1],
							};
						}),
					};
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi;
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.saveLoading = false;
								this.onResetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
		uploadImg(dataImg) {
			this.ruleForm.images = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.medicineClass {
		.el-select {
			width: calc(50% - 16px);
		}
		span {
			margin: auto 8px;
		}
	}
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
	width: 100px;
	height: 100px;
}
/deep/ .el-upload {
	.el-button--primary {
		border-color: #c4c4c4;
		color: #333;
		background-color: #fff;
		margin-left: 0;
	}
	.is-disabled {
		background-color: #f4f3f3 !important;
		border-color: #e4e7ed !important;
		color: #c0c4cc !important;
	}
}
/deep/ .el-upload--picture-card {
	width: 100px;
	height: 100px;
	line-height: 100px;
}
/deep/ .el-button {
	margin-left: 16px;
}
.hide {
	// display: none;
	/deep/ .el-upload-list {
		.el-icon-close,
		.el-icon-close-tip {
			display: none;
		}
	}
}

/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>